import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { GetServerSidePropsContext } from 'next';
import { IServerProps } from 'common/types/common';

const HeroHomepage = dynamic(() =>
  import('modules/homepage/HeroHomepage').then(mod => mod.HeroHomepage),
);
const HomepageAbout = dynamic(() =>
  import('modules/homepage/HomepageAbout').then(mod => mod.HomepageAbout),
);
const WorkCaseStudiesCarousel = dynamic(() =>
  import('modules/work/WorkCaseStudiesCarousel').then(mod => mod.WorkCaseStudiesCarousel),
);
const CTA = dynamic(() => import('common/components/Cta').then(mod => mod.CTA));
const Layout = dynamic(() => import('layout').then(mod => mod.Layout));

const HomePage = () => {
  const { t } = useTranslation('meta');

  const schemaMarkup = [
    {
      '@type': 'OnlineBusiness',
      name: 'Nordit d.o.o.',
      url: 'https://nordit.co',
      logo: 'https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/logo%2FNordit%20logo%20-%20primary%20white%2FNordit%20logo%20-%20primary%20white%402x.png?alt=media&token=d979ae78-414f-4fd1-bfb5-dc31ad8ce3cf',
      sameAs: [
        'https://www.facebook.com/nordit.software.development',
        'https://www.instagram.com/nordit.software.development',
        'https://www.linkedin.com/company/nordit-software-development',
        'https://x.com/NorditSoftware',
      ],
    },
  ];
  const seo = {
    alternate: t('homepage.alternate', { returnObjects: true }),
    description: t('homepage.description'),
    image: t('homepage.image'),
    schemaMarkup,
    title: t('homepage.title'),
  };
  return (
    <Layout dark seo={seo}>
      <HeroHomepage />
      <HomepageAbout />
      <WorkCaseStudiesCarousel />
      <CTA dark />
    </Layout>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
): Promise<IServerProps<IHomePageProps>> => {
  const locale = await serverSideTranslations(context.locale || 'en', [
    'common',
    'meta',
    'pageHomepage',
    'projectItemsOverview',
  ]);

  return {
    props: {
      data: { referer: context.req.headers.referer || null },
      ...locale,
    },
  };
};

interface IHomePageProps {
  data: { referer?: string | null } | Record<string, never>;
}

export default HomePage;
